<template>
    <div>
        <b-card
            no-body
        >
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Task templates`) }}</h1>
                            <b-button
                                v-if="displayExtentControls"
                                variant="primary"
                                :to="{ name: 'create-task-template' }"
                                class="float-right"
                            >
                                <feather-icon icon="PlusCircleIcon"/>
                                {{ i18nT(`Add new`) }}
                            </b-button>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
                        <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                dataMeta.from
                            }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                dataMeta.of
                            }} {{ i18nT(`entries`) }}</span>
                    </div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="4"
                            v-if="controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Type`)"
                                label-for="taskType"
                            >
                                <v-select
                                    id="taskType"
                                    v-model="taskType"
                                    :options="taskTypes"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="4"
                            v-if="displayExtentControls && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Group`)"
                                label-for="taskGroup"
                            >
                                <v-select
                                    id="taskGroup"
                                    v-model="taskGroup"
                                    :options="taskGroups"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="4"
                            v-if="displayExtentControls && !isTemplate && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Assigned to`)"
                                label-for="assignee"
                            >
                                <v-select
                                    id="assignee"
                                    v-model="assignedTo"
                                    :options="assigneeFilter"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="4"
                            v-if="!isTemplate && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Status`)"
                                label-for="status"
                            >
                                <v-select
                                    id="status"
                                    v-model="status"
                                    :options="statusFilters"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="4"
                            v-if="displayExtentControls && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Software`)"
                                label-for="software"
                            >
                                <v-select
                                    id="software"
                                    v-model="software"
                                    :options="softwareFilter"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div>

                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0 && iCan('tasks', 'write')"
                            >
                                <b-dropdown-item @click="onBulkCopy(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onBulkDelete(data)">
                                    <feather-icon icon="TrashIcon" color="red"/>
                                    <span class="align-middle ml-50" style="color: red">{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                            >
                                <template v-slot:button-content>
                                    <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{i18nT(`Export`)}}
                                </template>
                                <b-dropdown-item @click="onExportExcel()">
                                    <feather-icon icon="CopyIcon" />
                                    <span class="align-middle ml-50">{{i18nT(`Excel`)}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onExportCsv()">
                                    <feather-icon icon="CopyIcon" />
                                    <span class="align-middle ml-50">{{i18nT(`CSV`)}}</span>
                                </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div role="group" class="input-group input-group-merge">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refTaskListTable"
                        :items="fetchTasks"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >

                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>

                        <template #cell(name)="data">
                            <b-link
                                :to="{ name: 'task-view', params: { id: data.item.Id }}"
                                class="font-weight-bold"
                            >
                                {{ data.item.Label }}
                            </b-link>
                        </template>

                        <template #cell(type)="data">
                            {{ getTypeName(data.item.Type) }}
                        </template>

                        <template #cell(group)="data">
                            {{ Object.values(data.item.TaskGroups).join(", ") }}
                            <!-- <div v-html="data.item.TaskGroups" /> -->
                        </template>

                        <template #cell(due)="data">
                            {{ data.item.TaskDueDate | date }}
                            {{ data.item.TaskRepeated }}
                        </template>

                        <template #cell(created)="data">
                            {{ data.item.CreatedAt | date }}
                        </template>

                        <template #cell(status)="data">
                            <a
                                class="badge badge-pill badge-light-danger"
                                v-if="data.item.TaskIsLate"
                            >
                                Late
                            </a>
                            <a
                                :class="data.item.Completed == 0 ? 'badge badge-pill badge-light-success': 'badge badge-pill badge-light-secondary'"
                                v-if="!data.item.TaskIsLate"
                            >
                                {{ getStatusName(data.item.Completed) }}
                            </a>
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item :to="{ name: 'edit-task-template', params: { id: data.item.Id } }" v-if="iCan('tasks', 'write')">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item :to="{ name: 'task-view', params: { id: data.item.Id } }">
                                    <feather-icon icon="EyeIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`View`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onCopy(data)" v-if="iCan('tasks', 'write')">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onDelete(data)" v-if="iCan('tasks', 'write')">
                                    <feather-icon icon="TrashIcon" color="red"/>
                                    <span class="align-middle ml-50" style="color: red">{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                </div>
                <!-- Disable grid view -->
                <b-tabs pills align="center" v-if="false">
                    <b-tab active>
                        <template #title>
                            <feather-icon icon="ListIcon" size="24" style="margin: 0;"/>
                        </template>
                        <div>
                            <div class="row mx-2">
                                <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                                    <v-select
                                        v-model="perPage"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="perPageOptions"
                                        :clearable="false"
                                        class="per-page-selector d-inline-block"
                                    />
                                    <b-dropdown
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        text="Actions"
                                        size="md"
                                        class="d-inline ml-1"
                                        variant="outline-secondary"
                                        v-if="selectedItems.length > 0"
                                    >
                                        <b-dropdown-item @click="onBulkCopy(data)">
                                            <feather-icon icon="CopyIcon"/>
                                            <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="onBulkDelete(data)">
                                            <feather-icon icon="TrashIcon" color="red"/>
                                            <span class="align-middle ml-50"
                                                  style="color: red">{{ i18nT(`Delete`) }}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    <!-- <b-dropdown
                                      size="md"
                                      class="d-inline ml-1"
                                      variant="outline-secondary"
                                    >
                                      <template v-slot:button-content>
                                        <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{i18nT(`Export`)}}
                                      </template>
                                      <b-dropdown-item @click="onExportExcel()">
                                        <feather-icon icon="CopyIcon" />
                                        <span class="align-middle ml-50">{{i18nT(`Excel`)}}</span>
                                      </b-dropdown-item>
                                      <b-dropdown-item @click="onExportCsv()">
                                        <feather-icon icon="CopyIcon" />
                                        <span class="align-middle ml-50">{{i18nT(`CSV`)}}</span>
                                      </b-dropdown-item>
                                    </b-dropdown> -->
                                </div>
                                <!-- search input -->
                                <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                                    <div class="custom-search d-flex justify-content-end">
                                        <b-form-group>
                                            <div role="group" class="input-group input-group-merge">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <feather-icon icon="SearchIcon"/>
                                                    </div>
                                                </div>
                                                <b-form-input
                                                    v-model="searchQuery"
                                                    :placeholder="i18nT(`Search`)"
                                                    type="text"
                                                    class="d-inline-block"
                                                />
                                            </div>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                            <b-table
                                ref="refTaskListTable"
                                :items="fetchTasks"
                                responsive
                                :fields="tableColumns"
                                primary-key="id"
                                :sort-by.sync="sortBy"
                                show-empty
                                :empty-html="emptyHtml"
                                :sort-desc.sync="isSortDirDesc"
                                class="position-relative"
                                @refreshed="onTableRefresh"
                                v-model="currentItems"
                            >

                                <template v-slot:head(checkbox)="data">
                                    <span>{{ data.label.toUpperCase() }}</span>
                                    <b-form-checkbox
                                        class="d-inline-block pl-0"
                                        @change="onSelectAll"
                                        v-model="selectAllStatus"
                                    >
                                    </b-form-checkbox>
                                </template>

                                <template #cell(checkbox)="data">
                                    <b-form-checkbox
                                        v-model="selectedItems"
                                        :value="data.item.Id"
                                    />
                                </template>

                                <template #cell(name)="data">
                                    <b-link
                                        :to="{ name: 'task-view', params: { id: data.item.Id }}"
                                        class="font-weight-bold"
                                    >
                                        {{ data.item.Label }}
                                    </b-link>
                                </template>

                                <template #cell(type)="data">
                                    {{ getTypeName(data.item.Type) }}
                                </template>

                                <template #cell(group)="data">
                                    {{ Object.values(data.item.TaskGroups).join(", ") }}
                                    <!-- <div v-html="data.item.TaskGroups" /> -->
                                </template>

                                <template #cell(due)="data">
                                    {{ data.item.TaskDueDate | date }}
                                    {{ data.item.TaskRepeated }}
                                </template>

                                <template #cell(created)="data">
                                    {{ data.item.CreatedAt | date }}
                                </template>

                                <template #cell(status)="data">
                                    <a
                                        class="badge badge-pill badge-light-danger"
                                        v-if="data.item.TaskIsLate"
                                    >
                                        Late
                                    </a>
                                    <a
                                        :class="data.item.Completed == 0 ? 'badge badge-pill badge-light-success': 'badge badge-pill badge-light-secondary'"
                                        v-if="!data.item.TaskIsLate"
                                    >
                                        {{ getStatusName(data.item.Completed) }}
                                    </a>
                                </template>

                                <template #cell(action)="data">
                                    <b-dropdown
                                        variant="link"
                                        toggle-class="p-0"
                                        no-caret
                                        :right="$store.state.appConfig.isRTL"
                                    >

                                        <template #button-content>
                                            <feather-icon
                                                icon="MoreVerticalIcon"
                                                size="16"
                                                class="align-middle text-body"
                                            />
                                        </template>
                                        <b-dropdown-item :to="{ name: 'edit-tasks', params: { id: data.item.Id } }">
                                            <feather-icon icon="EditIcon"/>
                                            <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item :to="{ name: 'task-view', params: { id: data.item.Id } }">
                                            <feather-icon icon="EyeIcon"/>
                                            <span class="align-middle ml-50">{{ i18nT(`View`) }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="onCopy(data)">
                                            <feather-icon icon="CopyIcon"/>
                                            <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="onDelete(data)">
                                            <feather-icon icon="TrashIcon" color="red"/>
                                            <span class="align-middle ml-50"
                                                  style="color: red">{{ i18nT(`Delete`) }}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>

                        </div>
                    </b-tab>

                    <b-tab>
                        <template #title style="text-align: center">
                            <feather-icon icon="GridIcon" size="24" style="margin: 0;"/>
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    cols="12"
                                    md="4"
                                    v-for="task in tasks"
                                    :key="task.Id"
                                >
                                    <b-card
                                        style="border: 1px solid rgba(0, 0, 0, 0.07);"
                                    >
                                        <b-card-body class="p-0">
                                            <div class="media">
                                                <div class="media-body">
                                                    <h4 class="mb-0">
                                                        <a>{{ task.Label }}</a>
                                                    </h4>
                                                    <p class="mb-0">{{ task.TaskType }}</p>
                                                </div>
                                            </div>
                                            <p class="my-1" v-html="task.Description"></p>
                                            <div class="mt-1">
                                                <h6 class="section-label">
                                                    {{ i18nT(`Created`) }}
                                                </h6>
                                                <div>
                                                    {{ task.CreatedAt | date }}
                                                </div>
                                            </div>

                                            <div class="mt-1">
                                                <h6 class="section-label">
                                                    {{ i18nT(`Last update`) }}
                                                </h6>
                                                <div>
                                                    {{ task.UpdatedAt | date }}
                                                </div>
                                            </div>

                                            <div class="mt-1">
                                                <h6 class="section-label">
                                                    {{ i18nT(`Due date`) }}
                                                </h6>
                                                <div>
                                                    {{ task.TaskDueDate }}
                                                </div>
                                            </div>
                                            <div>
                                            </div>

                                        </b-card-body>

                                        <b-card-footer class="pb-0 pt-1 pr-1 pl-1 mt-2 ml-n2 mr-n2 text-top text-right">
                                            <b-dropdown
                                                class="float-right"
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                                right
                                            >

                                                <template #button-content>
                                                    <feather-icon
                                                        icon="MoreVerticalIcon"
                                                        size="16"
                                                        class="align-middle"
                                                    />
                                                </template>
                                                <b-dropdown-item :to="{ name: 'edit-tasks', params: { id: task.Id } }">
                                                    <feather-icon icon="EditIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item :to="{ name: 'task-view', params: { id: task.Id } }">
                                                    <feather-icon icon="EyeIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`View`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onCopy({item: task})">
                                                    <feather-icon icon="CopyIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onDelete({item: task})">
                                                    <feather-icon icon="TrashIcon" color="red"/>
                                                    <span class="align-middle ml-50"
                                                          style="color: red">{{ i18nT(`Delete`) }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                    dataMeta.from
                                }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                    dataMeta.of
                                }} {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalTasks"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    BFormInput,
    VBToggle,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useTaskList from './useTaskTemplateList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAuth from '@/auth/useAuth';
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        BCardFooter,
        BCardBody,
        vSelect,
        BFormCheckbox,
        BFormInput
    },
    data() {
        const displayExtentControls =
            useAuth.getCurrentSoftware() != 'ca' &&
            useAuth.getCurrentSoftware() != 'em' &&
            this.iCan('tasks', 'write')
        return {
            taskTypes: [],
            taskGroups: [],
            assigneeFilter: [],
            statusFilters: [],
            softwareFilter: [],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            displayExtentControls,
            isTemplate: router.currentRoute.meta.template === 1,
            avalableFields: [
                {label: this.i18nT(`Type`), key: 'type', visible: true},
                {label: this.i18nT(`Created`), key: 'created', visible: true},
            ],
            softwarePath: useAuth.getCurrentSoftware(),
            controlVisible: true,
        }
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
    },
    created() {

        let storePreferences = useAuth.getColumnPreferences('taskTemplates')
          if(storePreferences) {
            this.avalableFields = storePreferences
        }

        this.$http.get(
            `tasks/taskTypes`
        ).then(({data}) => {
            const types = (data.data).map(type => ({
                value: type.Id,
                text: type.Label
            }))

            types.unshift({
                value: -1,
                text: this.i18nT(`All types`)
            })

            this.taskTypes = types
        })

        this.$http.get(
            `tasks/taskGroupsFilter`
        ).then(({data}) => {
            const taskGroups = dictToSelectArray(data.data);

            taskGroups.unshift({
                value: -1,
                text: this.i18nT(`All groups`)
            })

            this.taskGroups = taskGroups
        })

        this.$http.get(
            `tasks/assignedUsersFilter`
        ).then(({data}) => {
            this.assigneeFilter = dictToSelectArray(data.data)
        })

        this.$http.get(
            `tasks/taskStatuses`
        ).then(({data}) => {
            this.statusFilters = dictToSelectArray(data.data)
            this.status = router.currentRoute.meta.template === 1 ? -1 : 0
        })

        this.$http.get(
            `system/softwares`
        ).then(({data}) => {
            const softwareFilter = dictToSelectArray(data.data)

            softwareFilter.unshift({
                value: -1,
                text: this.i18nT(`All`)
            })

            this.softwareFilter = softwareFilter
        })

        this.fetchTasks(null, this.determineVisibility);

    },
    methods: {
        determineVisibility(invoiceArray) {
            if(invoiceArray.length===0){
              this.controlVisible = false;
            }
        },
        getStatusName(statusId) {
            const status = this.statusFilters.find(type => type.value == statusId)
            return status ? status.text : '';
        },
        getTypeName(typeId) {
            const type = this.taskTypes.find(type => type.value == typeId)
            return type ? type.text : '';
        },
        getAssigneeName(assigneeId) {
            try {
                if (this.assigneeFilter.length === 0 || assigneeId == null) {
                    return ""
                }

                return this.assigneeFilter.filter(assignee => assignee.value === assigneeId + "")[0].text
            } catch (ex) {
                return ""
            }
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map((namePart) => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase();
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(task) {
            this.$http.post(
                `tasks/copyTask?task_id=${task.item.Id}`
            ).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Task Copied`,
                        icon: 'InfoIcon',
                        variant: 'success',
                    },
                })
                this.refetchData()
            })
        },
        onDelete(task) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.delete(
                        `tasks?id=${task.item.Id}`
                    ).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The task has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.refetchData()
                    })
                }
            })
        },
        completeTask(task) {
            const formData = new FormData();
            formData.append('id', task.Id)
            formData.append('completed', 1 - task.Completed)
            this.$http
                .post("tasks/completedStatus", formData)
                .then(() => {
                    this.refetchData()
                })
                .catch(() => {
                    this.refetchData()
                });
        },
        onBulkCopy() {
            this.$http.post(
                `tasks/copyTask?task_id=${this.selectedItems.join(",")}`
            ).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Task(s) Copied`,
                        icon: 'InfoIcon',
                        variant: 'success',
                    },
                })

                this.selectedItems = []
                this.selectAllStatus = false
                this.refetchData()
            })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.delete(
                        `tasks?id=${this.selectedItems.join(",")}`
                    ).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The task has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.selectedItems = []
                        this.selectAllStatus = false
                        this.refetchData()
                    })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {

        },
        onExportCsv() {

        }
    },
    setup() {
        const statusOptions = [
            'Downloaded',
            'Draft',
            'Paid',
            'Partial Payment',
            'Past Due',
        ]

        const {
            fetchTasks,
            tableColumns,
            perPage,
            currentPage,
            totalTasks,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTaskListTable,
            statusFilter,
            taskType,
            taskGroup,
            assignedTo,
            status,
            software,
            tasks,
            refetchData
        } = useTaskList(router.currentRoute.meta.template === 1)

        return {
            fetchTasks,
            tableColumns,
            perPage,
            currentPage,
            totalTasks,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTaskListTable,
            statusFilter,
            statusOptions,
            taskType,
            taskGroup,
            assignedTo,
            status,
            software,
            avatarText,
            tasks,
            refetchData
        }
    },
    computed: {
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your tasks templates. No tasks have been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/"+ this.softwarePath +"/create-task-template\"'>" + this.i18nT(`Create task`) + "</button>"
        },
        computedColumns() {

            let col = [...this.tableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('taskTemplates', this.avalableFields);

            return col
      },
    }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
